import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bbci',
  templateUrl: './bbci.component.html',
  styleUrls: ['./bbci.component.scss']
})
export class BbciComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
