import {Component, Input, OnInit} from '@angular/core';
import {Class} from '../class';

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.scss']
})
export class ClassComponent implements OnInit {

  @Input() class: Class;
  constructor() { }

  ngOnInit() {
  }

}
