import {Component, Inject, OnInit} from '@angular/core';
import {Class} from '../class';
import {ClassService} from '../class.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-classlist',
  templateUrl: './classlist.component.html',
  styleUrls: ['./classlist.component.scss']
})
export class ClasslistComponent implements OnInit {
  classes: Class[];
  constructor(
    classService: ClassService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.classes = classService.getClasses();

}

  ngOnInit() {
    this.document.body.classList.add('page-classes');
  }

}
