import { Injectable } from '@angular/core';
import { CLASSES } from './mock-classes';


@Injectable({
  providedIn: 'root'
})
export class ClassService {

  constructor() { }

  getClasses() {
    return CLASSES;
  }
}
