import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bbcd',
  templateUrl: './bbcd.component.html',
  styleUrls: ['./bbcd.component.scss']
})
export class BbcdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
