import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit() {
    this.document.body.classList.add('page-about');
  }

}
