import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent} from './contact/contact.component';
import { DoulaComponent} from './doula/doula.component';
import { AssistantComponent} from './assistant/assistant.component';
import {MissingAssetSourceRootException} from '@angular-devkit/build-angular/src/utils';
import {ColorpickerComponent} from './colorpicker/colorpicker.component';
import {TrainingsComponent} from './trainings/trainings.component';
import {ServicesComponent} from './services/services.component';
import {ClasslistComponent} from './classes/classlist/classlist.component';



const routes: Routes = [
  // redirects
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: 'midwifery', pathMatch: 'full', redirectTo: 'services/assistant'},
  {path: 'midwife', pathMatch: 'full', redirectTo: 'services/assistant'},
  {path: 'midwifeassistant', pathMatch: 'full', redirectTo: 'services/assistant'},
  {path: 'assistant', pathMatch: 'full', redirectTo: 'services/assistant'},
  {path: 'doula', pathMatch: 'full', redirectTo: 'services/doula'},
  {path: 'bbc', pathMatch: 'full', redirectTo: 'classes'},
  // main path
  {path: 'home', component: HomeComponent},
  {path: 'about', component: AboutComponent, data: {
      title: 'About',
      parallax: [

      ],
    }
  },
  {path: 'contact', component: ContactComponent, data: {
      title: 'Contact',
      parallax: [
        // '/assets/images/photos/training/web/BBCInstructorTrainingWEB-16.jpg',
        // '/assets/images/photos/training/web/BBCInstructorTrainingWEB-9.jpg'
      ]
    }
  },
  {
    path: 'services',
    data: { title: 'Services'},
    children: [
      {
        path: '',
        component: ServicesComponent,
      },
      {
        path: 'doula',
        component: DoulaComponent,
        data: { title: 'Doula'}
      },
      {
        path: 'assistant',
        component: AssistantComponent,
        data: { title: 'Midwife Assistant'}
      },
    ],
  },
  {path: 'classes', component: ClasslistComponent, data: {
      parallax: [
        // '/assets/images/photos/training/web/BBCInstructorTrainingWEB-5.jpg',
        // '/assets/images/photos/training/web/BBCInstructorTrainingWEB-13.jpg',
        // '/assets/images/photos/training/web/BBCInstructorTrainingWEB-3.jpg',
        // '/assets/images/photos/training/web/BBCInstructorTrainingWEB-25.jpg',
        // '/assets/images/photos/training/web/BBCInstructorTrainingWEB-29.jpg',
      ],
      title: 'Classes'
    }
  },
  {path: 'trainings', component: TrainingsComponent, data: { title: 'Training'} },
  {path: 'test', component: ColorpickerComponent, data: {
      parallax: [
        '/assets/images/photos/training/web/BBCInstructorTrainingWEB-5.jpg',
        '/assets/images/photos/training/web/BBCInstructorTrainingWEB-13.jpg',
        '/assets/images/photos/training/web/BBCInstructorTrainingWEB-3.jpg',
        '/assets/images/photos/training/web/BBCInstructorTrainingWEB-25.jpg',
        '/assets/images/photos/training/web/BBCInstructorTrainingWEB-29.jpg',
      ],
      title: 'Test'
    }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
