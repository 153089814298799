import { Component } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Blue Lotus Birth Services';
  private parallaxContainer: HTMLElement;
  parallaxElements: HTMLElement[];
  parallaxQuantity = 0;
  subs: Array<Subscription> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.subs[0] = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        if (route.data.title) {
          this.title += ' - ' + route.data.title;
          titleService.setTitle(this.title);
        }

        if (route.data.parallax) {
          const parallax = [].concat(route.data.parallax);

          for (const url of parallax) {
            this.addParallaxImage(url);
          }
        }
      });




    //
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //
    //     const parallax = (this.getRouteDataAttribute('parallax', router.routerState, router.routerState.root)).reverse();
    //     if (parallax && parallax.length) {
    //       const parallaxContainer = document.getElementById('parallax');
    //       console.log('parallax', parallax);
    //       console.log('parallaxccontainer', parallaxContainer);
    //       for (let i in parallax) {
    //         const image = document.createElement('div');
    //         image.style.backgroundImage = `url('${parallax[i]}')`;
    //         image.className = 'parallax';
    //         parallaxContainer.prepend(image);
    //       }
    //     }
    //
    //     const title = this.getRouteDataAttribute('title', router.routerState, router.routerState.root).join('-');
    //     // const currentRouteComponent = router.routerState.root.firstChild.component;
    //     // console.log('currentRouteComponent', currentRouteComponent);
    //     // console.log('outlet', router.routerState.snapshot.root.outlet);
    //     // console.log('firstChild', router.routerState.root.firstChild);
    //     console.log('title', title);
    //     titleService.setTitle(title);
    //   }
    // });
  }
  async getParallaxContainer() {
    while (!this.parallaxContainer) {
      await new Promise(resolve => {
        this.parallaxContainer = document.getElementById('parallax');
        setTimeout(resolve, 1000);
      });
    }
    return this.parallaxContainer;
  }

  async removeParallaxImages() {
    (await this.getParallaxContainer()).querySelectorAll('.parallax').forEach((element, key, parent) => {
      element.remove();
    });
  }

  async addParallaxImage(url) {
    console.log('should be adding ', url);
    while (!this.parallaxContainer) {
      await new Promise(r => setTimeout(r, 500));
    }

    const image = document.createElement('div');
    image.style.backgroundImage = `url('${url}')`;
    image.className = 'parallax';
    (await this.getParallaxContainer()).append(image);
  }
  getRouteDataAttribute(attribute, state, parent) {
    console.log('getRouteDataAttribute(attribute,state,parent)', attribute, state, parent);
    let data = [];

    if (parent && parent.snapshot.data && parent.snapshot.data[attribute]) {
      console.log(parent.snapshot.component.name);
      console.log(parent.snapshot.component);
      const dataTemp = parent.snapshot.data[attribute];
      data = data.concat(dataTemp);
    }

    if (state && parent) {
      const dataTemp = this.getRouteDataAttribute(attribute, state, state.firstChild(parent));
      data = data.concat(dataTemp);
    }
    console.log(data);
    return data;
  }

  ngOnInit() {

    window.addEventListener('scroll', this.scroll, true);
    window.addEventListener('load', (event) => {
      (async () => {
        const parallaxContainer = await this.getParallaxContainer();
        this.parallaxElements = Array.from(document.getElementsByClassName('parallax') as HTMLCollectionOf<HTMLElement>);
        this.parallaxQuantity = this.parallaxElements.length;
        console.log('this.parallaxQuantity', this.parallaxQuantity);
        console.log('this.parallaxElements', this.parallaxElements);
      })();
    }, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    const that = this;
    window.requestAnimationFrame(function() {
      for (let i = 0; i < that.parallaxQuantity; i++) {
        const currentElement = that.parallaxElements[i];
        const scrolled = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        currentElement.style.transform = ('translate3d(0,' + scrolled * -0.3 + 'px, 0)');
      }
    });
  }
}
