import { Class } from './class';

export const CLASSES: Class[] = [
  {
    id: 1,
    name: '10 Week Comprehensive Class',
    description: [
      'Our most popular class! This class includes everything taught in our other classes! It is the most information and the BEST deal!',
      'If you desire an unmedicated birth, are first-time parents, or are preparing for a VBAC, this class will prepare you on all levels – physically, mentally, and emotionally. Online workbook is included.',
      'Class time is 25 hours and is taught over a 10-week period, or ten 2.5 hour sessions.'
    ],
    subtitle: ''
  },
  {
    id: 2,
    name: 'Basic Training Class',
    description: [
      'This ONE-DAY course is designed for the extra busy couple. Topics covered include basic nutrition and exercise; what to expect during labor, epidurals and cesarean sections; coping techniques, comfort measures, and writing a birth plan.',
      'Class time is approximately 7 hours with a one hour lunch break.'
    ],
    subtitle: ''
  },
  {
    id: 3,
    name: 'Hospital Birth Class',
    description: [
      'Birthing in the hospital? This class is for you! Our hospital class is designed for the couple who wants more than a typical class. More education. More fun. More relaxation and labor practice. More information on interventions, including medicated and cesarean births. This class give you the ability to make informed decisions as your birth unfolds. When choosing this class, we highly recommend taking our Homecoming Class, as well.',
      'Class time is 15 hours, taught over six 2.5 hour sessions.'
    ],
    subtitle: ''
  },
  {
    id: 4,
    name: 'Home & Birth Center Class',
    description: [
      'Planning to have your baby at home or at a birth center? This class will give you the nuts and bolts of labor. Learn what happens before, during, and after birth, as well as variations of labor and watch birth videos of out-of-hospital births. When choosing this class, we highly recommend taking our Homecoming Class, as well. Workbook is included.',
      'Class time is 10 hours, taught over five 2.5 hour sessions.'
    ],
    subtitle: ''
  },
  {
    id: 5,
    name: 'Homecoming: Life With a New Recruit',
    description: [
      'This is your manual for early parenthood! Topics include breastfeeding (3.5 hour video course included), newborn procedures, babywearing, safe sleep, postpartum topics, and baby comfort techniques to help you and your partner get off to a great start with your new baby. Please note that all of this material is included in the&nbsp;Comprehensive Class. Workbook is included.',
      'Class time approximately 4 hours.'
    ],
    subtitle: ''
  },
  {
    id: 6,
    name: 'Reboot Refresher',
    description: [
      "This class is only offered as a private in person class. The best time to take this class is approximately 34-36 weeks. This class is best for those who have already taken a childbirth class with a previous pregnancy and need to brush up on their skills.",
      "Class will be tailored to your needs based on your previous experience(s) and current concerns – of BOTH partners. We’ll also cover Relaxation, Top 10 Tools for Dads, Communication, Labor &amp; Pushing Positions, Comfort Measures, adding a new baby to your family, and anything else you have questions or concerns about."
    ],
    subtitle: ''
  }
];
